import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Layout from "../layouts/MainLayout"
import Components from "../components"

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      metadata {
        ui_data
      }
    }
  `)
  const uidata = JSON.parse(data.metadata.ui_data)

  return (
    <Layout>{uidata?.content?.body?.map(block => Components(block))}</Layout>
  )
}

export default Index
