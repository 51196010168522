import React from "react";
import HomeSlider from "./home/HomeSlider";
import FeaturedCategories from "./home/TopCategories";
import TopProducts from "./home/Products";
// import BigSavings from "./";
import NewsletterModal from "./common/SubscriptionForm";
import RecentlyViewed from "./home/RecentlyViewed";

 
const Components = {
  HomeSlider: HomeSlider,
  FeaturedCategories: FeaturedCategories,
  TopProducts:TopProducts,
//   BigSavings:BigSavings,
  NewsletterModal:NewsletterModal,
  RecentlyViewed:RecentlyViewed,
};

export default block => {
    // component does exist
    if (typeof Components[block.component] !== "undefined" ) {
      if(!block.isShown) return;
      return React.createElement(Components[block.component], {
        key: block._uid,
        block: block
      });
    }
    // component doesn't exist yet
    return React.createElement(
      () => <div>The component {block.component} has not been created yet.</div>,
      { key: block._uid }
    );
  }