import React from "react";
import { graphql, StaticQuery, useStaticQuery } from "gatsby";

import Banner from "./banner";
const TopCategories = (props) => (
  // useStaticQuery
  <StaticQuery
    query={graphql`{
  categories: allFeatCategories {
    nodes {
      name
      seoMetaData {
        slug
      }
      itemCount
      thumbnail {
        childImageSharp {
          gatsbyImageData(
            width: 315
            height: 150
            quality: 100
            layout: CONSTRAINED
          )
        }
      }
    }
  }
}
`}
    render={({ allFeatCategories }) =>
    allFeatCategories && allFeatCategories?.nodes?.map((item, i) => (
        <div className="col-xl-2 col-lg-3 col-sm-4 col-6" key={i}>
          <Banner data={item} key={i} />
        </div>
      ))
    }
  />
);

export default TopCategories;
